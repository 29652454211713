import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Layout from '../components/layout'
import SEO from '../components/seo'
import TermEN from '../components/term-and-policy/term-en'
import TermTH from '../components/term-and-policy/term-th'

const TermOfUse = props => {
  return (
    <Layout>
      <SEO keywords={[`Term of use`]} />
      {props.app.lang === 'EN' ? <TermEN /> : <TermTH />}
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    app: state.app,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermOfUse)
